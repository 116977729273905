<template>
  <ModuleView v-if="dataLoaded">
    <template slot="buttons">
      <router-link
        tag="a"
        :title="$t('buttons.edit')"
        class="btn btn-success mr-1"
        :to="{ name: 'themeSpecialAndSeries_edit', params: { id: themeDetail.id }}"
        data-test="themeSpecialAndSeries_edit"
      >
        <i class="fa fa-edit"></i> {{ $t('buttons.edit') }}
      </router-link>
      <ButtonClose route-name="themeSpecialAndSeries_list" />
    </template>
    <template slot="body">
      <preloaderFull v-if="!dataLoaded" />
      <div class="row cards-warapper">
        <div class="col-lg-6 tag-detail-warapper">
          <div class="card card-body">
            <!-- Left col -->
            <table class="table table-striped table-bordered">
              <tbody>
              <tr>
                <th> {{ $t('themeSpecialAndSeries.specials.name') }} </th>
                <td> {{ themeDetail.name }} </td>
              </tr>
              <tr>
                <th>Id</th>
                <td> {{ themeDetail.id }} </td>
              </tr>
              <tr>
                <th> {{ $t('themeSpecialAndSeries.specials.description') }} </th>
                <td> <div v-html="themeDetail.description"></div> </td>
              </tr>
              <tr>
                <th> {{ $t('themeSpecialAndSeries.isActive') }} </th>
                <td>
                  <Checkbox
                    v-model="themeDetail.isActive"
                    id="special_isActive"
                    disabled
                  />
                </td>
              </tr>
              <tr>
                <th> {{ $t('themeSpecialAndSeries.specials.sitesInputLable') }} </th>
                <td> {{ siteTitle }} </td>
              </tr>
              <tr>
                <th> {{ $t('themeSpecialAndSeries.specials.allSitesCheckboxLabel') }} </th>
                <td>
                  <Checkbox
                    v-model="themeDetail.forChildSites"
                    id="special_all_sites_on"
                    disabled
                  />
                </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
        <!-- Right col -->
        <div class="col-lg-6 tag-detail-warapper">
          <div class="card card-body">
            <table class="table table-striped table-bordered">
              <tbody>
              <tr>
                <th> {{ $t('themeSpecialAndSeries.specials.heroImage') }} </th>
                <td>
                  <Media
                    v-if="themeDetail.heroImage"
                    :media="themeDetail.heroImage"
                    :media-usage-type="themeMediaTypes.hero"
                    :width="480"
                    :height="270"
                    show-media-info
                  />
                </td>
              </tr>
              <tr>
                <th> {{ $t('themeSpecialAndSeries.specials.listingImage') }} </th>
                <td>
                  <Media
                    v-if="themeDetail.listingImage"
                    :media="themeDetail.listingImage"
                    :media-usage-type="themeMediaTypes.listing"
                    :width="480"
                    :height="270"
                    show-media-info
                  />
                </td>
              </tr>
              <tr>
                <th> {{ $t('themeSpecialAndSeries.specials.meta.title') }} </th>
                <td> {{ themeDetail.meta.title }} </td>
              </tr>
              <tr>
                <th> {{ $t('themeSpecialAndSeries.specials.meta.description') }} </th>
                <td> {{ themeDetail.meta.description }} </td>
              </tr>
              <tr>
                <th> {{ $t('themeSpecialAndSeries.specials.meta.keywords') }} </th>
                <td> {{ themeDetail.meta.keywords }} </td>
              </tr>
              <tr>
                <th> {{ $t('tag.geneeaTag.generalInfo.createdAt') }} </th>
                <td> {{ themeDetail.createdAt | prettyDateTime }} </td>
              </tr>
              <tr>
                <th> {{ $t('tag.geneeaTag.generalInfo.modifiedAt') }} </th>
                <td> {{ themeDetail.modifiedAt | prettyDateTime }} </td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </template>
  </ModuleView>
</template>

<script>
import { mapGetters } from 'vuex'
import ModuleView from '@/components/ModuleView'
import ButtonClose from '@/components/shared/ButtonClose'
import PreloaderFull from '@/components/preloader/PreloaderFull.vue'
import { MEDIA_USAGE_TYPE_THEME_HERO, MEDIA_USAGE_TYPE_THEME_LISTING } from '@/model/ValueObject/MediaUsageTypes'
import Checkbox from '@/components/form/Checkbox.vue'
import Media from '@/components/shared/Media.vue'

export default {
  name: 'ThemeDetail',
  components: {
    Media,
    Checkbox,
    PreloaderFull,
    ModuleView,
    ButtonClose
  },
  data () {
    return {
      dataLoaded: false,
      themeMediaTypes: {
        hero: MEDIA_USAGE_TYPE_THEME_HERO,
        listing: MEDIA_USAGE_TYPE_THEME_LISTING
      }
    }
  },
  computed: {
    ...mapGetters('themeSpecialAndSeries', [
      'error',
      'formErrors',
      'themeDetail'
    ]),
    siteTitle () {
      if (this.themeDetail && this.themeDetail.siteId) {
        const allSites = this.$store.getters['site/allEnabledSorted']() ?? []
        return allSites.filter(x => x.id === this.themeDetail.siteId)[0]?.title ?? ''
      } else return ''
    }
  },
  methods: {
    getTheme () {
      this.dataLoaded = false
      this.$store.dispatch('themeSpecialAndSeries/fetchOneTheme', this.$route.params.id)
        .then(() => {
          this.dataLoaded = true
        })
    }
  },
  mounted () {
    this.getTheme()
  }
}
</script>

<style lang="scss" scoped>

h3 {
  color: #bbb;
  font-weight: 100;
}

.cards-warapper {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.tag-detail-warapper {
  p {
    font-size: 0.9rem;
    span {
      color: black;
      font-weight: 600;
      margin-right: 1rem;
    }
  }
}

</style>
